import { createBrowserHistory, Location } from "history";
//import { TrackPageView } from './tracking';
import config from "src/config.json";
import GA4React from "ga-4-react";
import { Track } from "src/runtime/pubsub/tracking";
export const history = createBrowserHistory();

let scrollLocation = 0;

const ga4react = new GA4React("G-2REBEL4BT5");

async function initGoogleAnalytics() {
    await ga4react.initialize();
}

const trackGA = (loc: any) => {
    if (config.env === config.envs.production) {
        if (loc.pathname.indexOf("/console") === -1) {
            if (GA4React.isInitialized()) {
                ga4react.pageview(loc.pathname + loc.search);
            }
        }
    }
};

if (config.env === config.envs.production) {
    initGoogleAnalytics();
}

trackGA(window.location);

history.listen((location, action) => {
    OnHistoryChange(location);
    if (action === "POP") {
        const toScrollPosition = scrollLocation;
        setTimeout(() => {
            window.scrollTo(0, toScrollPosition);
        }, 1000);
    }

    scrollLocation = window.scrollY;
});

export function OnHistoryChange(location: Location, init?: boolean) {
    Track("page_views", { path: location.pathname });
    trackGA(location);
}

export const OpenPage = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(`/${path}`);
};
