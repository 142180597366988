import { CreateScheet, css } from "packages/aphrodite/aphrodite";

export default function Page404() {
    return (
        <div className={css(Styles.page)}>
            <h1 className={css(Styles.title)}>404: page not found</h1>
        </div>
    );
}

const Styles = CreateScheet({
    page: {
        display: "flex",
        minHeight: 300,
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        fontWeight: 500,
        fontSize: 30,
    },
});
