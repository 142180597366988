import { css, CreateScheet } from "packages/aphrodite/aphrodite";
import { OpenPage } from "packages/history/history";
import Block from "packages/blocks/block.react";
import { DefaultLayout, LightBlock } from "src/themes/block";
import { Dark } from "src/themes/colors";
import { DarkLogo } from "src/themes/logo";
import Logo from "../logo/Logo.react";

export default function StaticWithBlock({ children }: React.PropsWithChildren<{}>) {
    return (
        <article className={ css(Styles.page) }>
            <aside className={ css(Styles.page_bg) }>
                <svg viewBox='0 0 100 100' preserveAspectRatio='none' width="100%" height="100%">
                    <path d='M 0 100 L 100 40, 100 100' className={ css(Styles.page_bg_path) } />
                    <path d='M -1 101 C 0 70, 100 60, 100 40' className={ css(Styles.page_bg_path) } />
                </svg>
            </aside>
            <section className={ css(Styles.form_outer) }>
                <section className={ css(Styles.form) }>
                    <div className={ css(Styles.logo) }>
                        <Logo theme={ DarkLogo } />
                    </div>
                    <div className={ css(Styles.form_block) } >
                        <Block {...{ theme: LightBlock, layout: DefaultLayout }} className={ css(Styles.block) }>
                            { children }
                        </Block>
                    </div>
                    <div className={css(Styles.footer)}>
                        <div className={css(Styles.footerBg)} />
                        <ul className={css(Styles.nav)}>
                            <li className={css(Styles.navItem)}>
                                <a className={css(Styles.navLink)} href="/privacy" onClick={OpenPage("privacy")}>Privacy policy</a>
                            </li>
                            <li className={css(Styles.navItem)}>
                                <a className={css(Styles.navLink)} href="/terms" onClick={OpenPage("terms")}>Terms</a>
                            </li>
                            <li className={css(Styles.navItem)}>
                                <a className={css(Styles.navLink)} href="/help" onClick={OpenPage("help")}>Help</a>
                            </li>
                        </ul>
                        <p className={css(Styles.footerBottom)}>© Rapid MPY {new Date().getFullYear()}. All rights reserved.</p>
                    </div>
                </section>
            </section>
        </article>
    )
}

const Styles = CreateScheet({
    page: {
        color:              Dark.default,
        backgroundColor:    "#f7fafc",
        minHeight:          "100vh",
        overflowX:          "hidden",
        transition:         "padding .1s",
    },
    page_bg: {
        position:           'absolute',
        top:                0,
        bottom:             0,
        width:              "100%",
        zIndex:             0,
        overflow:           "hidden",
    },
    page_bg_path: {
        fill:               "white",
    },
    form_outer: {
        maxWidth:       "90%",
        margin:         "auto",
    },
    form: {
        maxWidth:       350,
        margin:         "auto",
        position:       "relative",
        paddingTop:     25,
        minHeight:      "100vh",
        display:        "flex",
        flexDirection:  "column",
        '@media (max-width: 600px)': {
            paddingTop:     30,
        }
    },
    block: {
        width:          "100%",
        marginBottom:   20,
        padding:        "20px 26px",
    },
    logo: {
        padding:    "0 0 20px 0px",
        transition: "padding .1s",
        '@media (max-width: 600px)': {
            paddingBottom:      20,
        }
    },
    form_block: {
        display:        "flex",
    },
    footer: {
        display: "block",
        position: "relative",
        width: "100%",
        margin: "30px 0 20px 0",
        fontSize: 12,
        color: Dark.default,
    },
    footerBg: {
        height: 20,
        background: "linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent)",
        ":before": {
            height: 20,
            display: "block",
            content: '" "',
            background: "linear-gradient(to right,#fff,rgba(255,255,255,0),#fff)",
        }
    },
    nav: {
        display: "flex",
        margin: 0,
        padding: 0,
        alignItems: "center",
        justifyContent: "center",
    },
    navItem: {
        margin: "0 5px",
        fontSize: 13,
        listStyle: "none",
    },
    navLink: {
        color: Dark.default,
        textDecoration: "none",
        cursor: "pointer",
        ":hover": {
            textDecoration: "underline",
        }
    },
    footerBottom: {
        margin: "10px 0 0 0",
        textAlign: "center",
    }
});