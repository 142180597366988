import { ReactNode, Children } from "react";
import { InternalTheme, TabsTheme, TabsLayout } from './tabs.theme';
import { css, CreateScheetWithTheme, useTheme } from 'aphrodite';
export type { TabsTheme, TabsLayout };

/* if don't need some tab just display: none; it, don't hide it in React */
export default function Tabs({
    layout,
    theme,
    children,
    active,
}: {
    layout:     TabsLayout,
    theme:      TabsTheme,
    children:   ReactNode,
    active:     number,
}) {
    const Styles = useTheme({ styles: theme, layout }, StylesWithTheme);

    return (
        <ul className={ css(Styles.list) }>
            { Children.toArray(children).map((child, i) => (
                <li {...{
                    key:        i,
                    className:  css(Styles.item, i === active ? Styles.active : null),
                }}>
                    { child }
                </li>
            )) }
        </ul>
    );
}

const StylesWithTheme = CreateScheetWithTheme((theme?: InternalTheme) => { return {
    list: {
        display:    "flex",
        listStyle:  "none",
        margin:     theme && theme.layout.list.margin ? theme.layout.list.margin : 0,
        padding:    theme && theme.layout.list.padding ? theme.layout.list.padding : 0,
    },
    item: {
        margin:         theme && theme.layout.tab.margin ? theme.layout.tab.margin : 0,
        padding:        theme && theme.layout.tab.padding ? theme.layout.tab.padding : 0,
        color:          theme?.styles.plain.color,
        position:       "relative",
        overflow:       "hidden",
        ":nth-child(1n) svg": {
            fill:       theme?.styles.plain.color,
        },
        ":after": {
            content:            '""',
            position:           "absolute",
            bottom:             0,
            width:              "100%",
            backgroundColor:    theme?.styles.active.bottomLineColor,
            height:             theme?.layout.tab.bottomLineHeight,
            transition:         theme?.layout.tab.transition,
            transform:          "translate3d(0,150%,0)",
        }
    },
    active: {
        color:          theme?.styles.active.color,
        ":nth-child(1n) svg": {
            fill:       theme?.styles.active.color,
        },
        ":after": {
            transform:  "translate3d(0,0,0)",
        }
    }
}});