import { lazy, Suspense } from 'react';
import { css, CreateScheet } from 'aphrodite';
const Page = lazy(() => import('./faq.react'));

export default function LazyFAQ() {
    return (
        <Suspense {...{
            fallback: (
                <div className={ css(Styles.fallback) } />
            )
        }}>
            <Page />
        </Suspense>
    )
}

const Styles = CreateScheet({
    fallback: {
        flexGrow: 1,
        display: "flex",
    }
})