import ReactCodeInput from "packages/forms/CodeInput.react";
import { CreateScheetWithTheme, css, useTheme } from 'packages/aphrodite/aphrodite';
import { ErrorData } from 'packages/errors/errors';
import { useState } from 'react';
import { VerificationTheme } from './Verification.theme';
import { DefaultLayout } from 'src/themes/code_input';
import { history, OpenPage } from 'packages/history/history';
import { session, VerifyRequestedEmail } from "packages/session/session";
import { LoadButton } from "src/themes/button";
import { ResendEmailVerificationAPI } from "packages/session/ResendEmailVerificationAPI";
import { FormStyles } from "src/themes/form";
import SmoothLine from "packages/motion/SmoothLine.react";

export default function Verification({ theme, source_url }:{ theme: VerificationTheme, source_url?: string }) {
    const Styles = useTheme(theme, StylesWithTheme);
    const [ Loading, SetLoading ] = useState(false);
    const [ Err, SetErr ] = useState<ErrorData>();

    async function OnChangeCode(code: string) {
        SetErr(undefined);
    
        if (code.length === 6) {
            SetLoading(true);
            const err = await VerifyRequestedEmail(code);
            SetLoading(false);
            
            if (err !== null) {
                SetErr(err);
            } else {
                history.push(source_url || "/");
            }
        }
    }

    async function ResendCode(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        SetErr(undefined);

        SetLoading(true);
        const err = await ResendEmailVerificationAPI();
        SetLoading(false);

        if (err !== null) {
            SetErr(err);
        }
    }

    return (
        <section {...{
            className:  css(FormStyles.form),
        }}>
            <label {...{
                htmlFor:    "email",
                className:  css(Styles.label),
            }}>
                verification code has been sent to <strong>{ session.user.requested_email || "email" }</strong>
            </label>
            <div className={ css(Styles.code) }>
                <ReactCodeInput {...{
                    inputMode:      "numeric",
                    fields:         6,
                    inputLayout:    DefaultLayout,
                    inputTheme:     theme.input,
                    onChange:       OnChangeCode,
                    disabled:       Loading,
                }} />
            </div>
            <SmoothLine className={ css(FormStyles.label, FormStyles.error) }>
                { Err?.text }
            </SmoothLine>
            <footer className={ css(Styles.footer) }>
                <LoadButton {...{
                    styles:     Styles.footer_link,
                    theme:      theme.resend_buttom,
                    loading:    Loading,   
                    onClick:    ResendCode, 
                }}>
                    Resend
                </LoadButton>
                <LoadButton {...{
                    styles:     Styles.footer_link,
                    theme:      theme.resend_buttom,
                    loading:    false,
                    onClick:    OpenPage("create-account")
                }}>
                    Change email address
                </LoadButton>
            </footer>
        </section>
    )
}

const StylesWithTheme = CreateScheetWithTheme((theme?: VerificationTheme) => { return {
    label: {
        color:          theme?.label_color,
    },
    code: {
        display:        "flex",
        alignItems:     "center",
        justifyContent: "center",
    },
    submit: {
        display:        "block",
        marginTop:      20,
        marginBottom:   20,
    },
    footer: {
        paddingTop:     30,
        fontSize:       14,
        textAlign:      "center",
    },
    footer_link: {
        fontWeight:     500,
        display:        "block",
        textAlign:      "center",
        margin:         "0 auto",
    }
}})