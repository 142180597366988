import {
    Error as UploaderError,
    config,
    SignedInitResponse,
    SignatureResponse,
    Uploader,
    UploaderFactory,
    UploaderPartFactory,
    UploaderPart,
    CompleteResponse,
    CompleteResponseRuntime,
} from "./model";
import {
    ErrMissingUploader,
    ErrCantGetUploadID,
    ErrCantUploadPart,
    ErrCantComplete,
    ErrNotSupported,
    ErrCantSign,
} from "./errors";
import { get, post } from "packages/rest/api";
import axios from "axios";
import { OrderedMap } from "immutable";
import CreaetGUID from "packages/helpers/CreateGUID";

let Uploaders = OrderedMap<string, Uploader>();
const InitProgress = 10;
const PartsProgressGap = 80;

export async function InitUploader({
    bucket,
    prefix,
    file,
    file_name,
    part_size,
    on_progress,
}: config): Promise<Uploader> {
    if (!IsSupported()) {
        return OnError("", ErrNotSupported);
    }
    let uploader = UploaderFactory({
        Bucket: bucket,
        File: file,
        FileName: (prefix ? prefix + "/" : "") + file_name,
        OFN: file_name,
        OnProgress: on_progress,
    });

    if (part_size && part_size > 0) {
        uploader = uploader.set("PartSize", part_size);
    }

    const TotalParts = Math.ceil(uploader.File.size / uploader.PartSize) || 1;
    for (let PartNumber = 1; PartNumber <= TotalParts; PartNumber++) {
        const FromByte = (PartNumber - 1) * uploader.PartSize;
        const ToByte = PartNumber * uploader.PartSize;

        uploader = uploader.set(
            "Parts",
            uploader.get("Parts").set(
                PartNumber,
                UploaderPartFactory({
                    Blob: file.slice(FromByte, ToByte),
                    PartNumber: PartNumber,
                })
            )
        );
    }

    const GUID = CreaetGUID();
    Uploaders = Uploaders.set(GUID, uploader.set("GUID", GUID));

    return await ProccessUploader(GUID);
}

async function ProccessUploader(GUID: string): Promise<Uploader> {
    let uploader = Uploaders.get(GUID);
    if (!uploader) {
        return OnError(GUID, ErrMissingUploader);
    }

    // start upload
    uploader = await InitUpload(uploader);
    if (uploader.get("Error") !== null) {
        return OnError(GUID, uploader.get("Error"));
    }

    uploader = uploader.set("Progress", InitProgress);
    OnProgress(uploader);

    // upload parts
    for (let [key, part] of uploader.get("Parts").toArray()) {
        part = await UploadPart(uploader, part);
        uploader = uploader.setIn(["Parts", key], part);
    }

    // finish upload
    uploader = await CompleteUpload(uploader);
    if (uploader.get("Error") !== null) {
        return OnError(GUID, uploader.get("Error"));
    }

    uploader = uploader.set("Progress", 100);
    OnProgress(uploader);

    return uploader;
}

async function InitUpload(uploader: Uploader): Promise<Uploader> {
    const api_res = await get<SignedInitResponse>({
        url: "files/signs",
        query: {
            type: "init",
            content_type: uploader.get("File").type,
            bucket: uploader.get("Bucket"),
            file_name: uploader.get("FileName"),
        },
    });

    if (api_res[1] != null) {
        return uploader.set("Error", ErrCantSign.set("text", api_res[1].text));
    }

    let UploadID = "";
    try {
        const response = await axios.post<string>(api_res[0].request, "", {
            headers: {
                "content-type": uploader.get("File").type,
                "x-amz-acl": "public-read",
            },
        });

        const UploadIDs = response.data.match(/<UploadId>(.+)<\/UploadId>/);
        if (UploadIDs && UploadIDs[1]) {
            UploadID = UploadIDs[1];
        } else {
            return uploader.set("Error", ErrCantGetUploadID);
        }
    } catch (error) {
        return uploader.set("Error", ErrCantGetUploadID.set("code", 3));
    }

    return uploader.set("UploadID", UploadID).set("S3Key", api_res[0].s3_key);
}

async function UploadPart(
    uploader: Uploader,
    part: UploaderPart
): Promise<UploaderPart> {
    uploader = uploader.setIn(
        ["Parts", part.get("PartNumber"), "IsProcessing"],
        true
    );
    part = part.set("IsProcessing", true);

    const api_res = await get<SignatureResponse>({
        url: "files/signs",
        query: {
            type: "upload",
            bucket: uploader.get("Bucket"),
            s3_key: uploader.get("S3Key"),
            part_number: part.get("PartNumber"),
            upload_id: uploader.get("UploadID"),
        },
    });

    if (api_res[1] != null) {
        return part
            .set("Error", ErrCantSign.set("text", api_res[1].text))
            .set("IsProcessing", false);
    }

    let ETag = "";
    try {
        const response = await axios.put(api_res[0].request, part.get("Blob"), {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                uploader = uploader.setIn(
                    ["Parts", part.get("PartNumber"), "Progress"],
                    Math.floor(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                );

                OnProgress(uploader);
            },
        });

        ETag = response.headers.etag;
    } catch (error) {
        return part
            .set(
                "Error",
                ErrCantUploadPart.set(
                    "text",
                    error instanceof Error ? error.message : "Global error"
                )
            )
            .set("IsProcessing", false);
    }

    return part
        .set("ETag", ETag)
        .set("Progress", 100)
        .set("IsFinishing", true)
        .set("IsProcessing", false);
}

async function CompleteUpload(uploader: Uploader): Promise<Uploader> {
    const api_res = await get<SignatureResponse>({
        url: "files/signs",
        query: {
            type: "complete",
            bucket: uploader.get("Bucket"),
            s3_key: uploader.get("S3Key"),
            upload_id: uploader.get("UploadID"),
        },
    });

    if (api_res[1] != null) {
        return uploader.set("Error", ErrCantSign.set("text", api_res[1].text));
    }

    try {
        await axios.post<string>(
            api_res[0].request,
            `<CompleteMultipartUpload>
                ${uploader
                    .get("Parts")
                    .toList()
                    .toArray()
                    .map((part) => {
                        return `
                        <Part>
                            <PartNumber>${part.get("PartNumber")}</PartNumber>
                            <ETag>${part.get("ETag")}</ETag>
                        </Part>
                    `;
                    })
                    .join("")}
            </CompleteMultipartUpload>`,
            {
                headers: {
                    "content-type": "application/xml; charset=UTF-8",
                },
            }
        );
    } catch (error) {
        return uploader.set(
            "Error",
            ErrCantComplete.set(
                "text",
                error instanceof Error ? error.message : "Global error"
            )
        );
    }

    const api_res_comp = await post<CompleteResponse>({
        url: "files",
        body: {
            bucket: uploader.get("Bucket"),
            s3_key: uploader.get("S3Key"),
            file_name: uploader.get("OFN"),
        },
    });
    if (api_res_comp[1]) {
        return uploader.set(
            "Error",
            ErrCantComplete.set("text", api_res_comp[1].text)
        );
    }

    try {
        CompleteResponseRuntime.check(api_res_comp[0]);
    } catch (error) {
        return uploader.set(
            "Error",
            ErrCantComplete.set(
                "text",
                error instanceof Error ? error.message : "Global error"
            )
        );
    }

    uploader = uploader.set("Result", api_res_comp[0].file_id);
    uploader = uploader.set("ResultFile", api_res_comp[0].file);

    return uploader;
}

function OnError(GUID: string, Err: UploaderError | null): Uploader {
    let uploader = Uploaders.get(GUID);
    if (!uploader) {
        uploader = UploaderFactory();
    }

    return uploader.set("Error", Err);
}

function OnProgress(uploader: Uploader) {
    if (uploader.get("Progress") < InitProgress + PartsProgressGap) {
        const TotalParts = uploader.get("Parts").size;
        const ProgressPerPart = PartsProgressGap / TotalParts;
        let CurrentProgress = InitProgress;

        uploader.get("Parts").forEach((p) => {
            CurrentProgress += Math.ceil(
                ProgressPerPart * (p.get("Progress") / 100)
            );
        });
        uploader = uploader.set("Progress", CurrentProgress);
    }

    Uploaders = Uploaders.set(uploader.get("GUID"), uploader);

    const callback = uploader.get("OnProgress");
    if (typeof callback === "function") {
        callback(uploader);
    }
}

function IsSupported(): boolean {
    return !(
        typeof File === "undefined" ||
        typeof Blob === "undefined" ||
        !(
            Blob.prototype.slice ||
            //@ts-ignore
            !!Blob.prototype.webkitSlice ||
            //@ts-ignore
            !!Blob.prototype.mozSlice
        )
    );
}
