import EventEmitter from "eventemitter3";
import { ErrorData } from "packages/errors/errors";
import { ShowDefaultToast } from "src/themes/toasts";
import { CreateUserAPI, CreateUserRequest } from "./CreateUserAPI";
import { LoadSessionAPI } from "./LoadSessionAPI";
import { Session, SessionRaw, SessionUser } from "./model";
import {
    RequestResetPasswordAPI,
    RequestResetPasswordRequest,
} from "./RequestResetPasswordAPI";
import { ResendEmailVerificationAPI } from "./ResendEmailVerificationAPI";
import {
    SetNewPasswordFromResetAPI,
    SetNewPasswordFromResetRequest,
} from "./SetNewPasswordFromResetAPI";
import { SignInAPI, SignInRequest } from "./SignInAPI";
import { SignInAsUserAPI } from "./SignInAsUserAPI";

import { SignOutAPI } from "./SignOutAPI";
import { VerifyRequestedEmailAPI } from "./VerifyRequestedEmailAPI";

export const Events = new EventEmitter<{
    "sign-in": () => void;
    "sign-out": () => void;
    "session-changes": () => void;
}>();

const SessionEmpty: Session = {
    user: {
        guid: "",
        primary_email: null,
        requested_email: null,
        primary_phone: null,
        requested_phone: null,
        full_name: "",
        company_name: "",
        company_url: "",
        company_logo: null,
    },
    extra: {
        has_experts: false,
    },
    policies: {
        can_sign_in: false,
        can_sign_up: false,
        can_sign_out: false,
        can_view_console: false,
        can_use_editor: false,
    },
    Reload: async function (this: Session) {
        await LoadSession();
        Events.emit("session-changes");
    },
    UpdateUserFields: function (this: Session, data: Partial<SessionUser>) {
        this.user = { ...this.user, ...data };
        Events.emit("session-changes");
    },
};

let session: Session = { ...SessionEmpty };

function SetSession(newSession: SessionRaw) {
    session = { ...session, ...newSession };
}

// usually such functions should not be located here
// but session is a bit different case
export async function LoadSession(): Promise<Session> {
    const res = await LoadSessionAPI();
    if (res[1] !== null) {
        console.error("session error: ", res[1].text);
    } else {
        SetSession(res[0]);
    }

    return session;
}

export async function SignUp(rq: CreateUserRequest): Promise<ErrorData | null> {
    const res = await CreateUserAPI(rq);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1];
    }

    if (res[0].policies.can_sign_out) {
        ShowDefaultToast("Successfully signed up");
    }

    Events.emit("session-changes");
    return null;
}

export async function VerifyRequestedEmail(
    code: string
): Promise<ErrorData | null> {
    const res = await VerifyRequestedEmailAPI(code);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1];
    }

    if (res[0].policies.can_sign_out) {
        ShowDefaultToast("Successfully signed in");
    }

    Events.emit("sign-in");
    return null;
}

export async function SignIn(rq: SignInRequest): Promise<ErrorData | null> {
    const res = await SignInAPI(rq);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1];
    }

    if (res[0].policies.can_sign_out) {
        ShowDefaultToast("Successfully signed in");
    }

    Events.emit("sign-in");
    return null;
}

export async function SignInAsUser(id: number): Promise<ErrorData | null> {
    const res = await SignInAsUserAPI(id);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1];
    }

    if (res[0].policies.can_sign_out) {
        ShowDefaultToast("Successfully signed in");
    }

    Events.emit("sign-in");
    return null;
}

export async function HiddenSignIn(): Promise<ErrorData | null> {
    await LoadSession();

    if (session.policies.can_sign_out) {
        ShowDefaultToast("Successfully signed in");
    }

    Events.emit("sign-in");
    return null;
}

export async function SignOut() {
    const res = await SignOutAPI();
    if (res[1] !== null) {
        console.error("session error: ", res[1].text);
    } else {
        window.location.href = "/";
        SetSession(res[0]);
    }
    ShowDefaultToast("Successfully signed out");
    Events.emit("sign-out");
}

export async function RequestResetPassword(rq: RequestResetPasswordRequest) {
    return await RequestResetPasswordAPI(rq);
}

export async function SetNewPasswordFromReset(
    rq: SetNewPasswordFromResetRequest
) {
    return await SetNewPasswordFromResetAPI(rq);
}

export async function ResendEmailVerification(): Promise<ErrorData | null> {
    return await ResendEmailVerificationAPI();
}

export function listenOnSignIn(func: () => void) {
    Events.addListener("sign-in", func);
}

export function unlistenOnSignIn(func: () => void) {
    Events.removeListener("sign-in", func);
}

export function listenOnSignOut(func: () => void) {
    Events.addListener("sign-out", func);
}

export function unlistenOnSignOut(func: () => void) {
    Events.removeListener("sign-out", func);
}

export function listenOnSessionChanges(func: () => void) {
    Events.addListener("session-changes", func);
}

export function unlistenOnSessionChanges(func: () => void) {
    Events.removeListener("session-changes", func);
}

export { session };
