import { InitUploader } from "./s3upload";
import { Uploader, Error } from "./model";
import { File as ResultFile } from "packages/files/model";

const Bucket = "rustgrade-web";
const Prefix = "external/images";

export async function UploadImage(
    Image: File,
    OnProgress?: (u: Uploader) => void
): Promise<[ResultFile | null, Error | null]> {
    const uploader = await InitUploader({
        bucket: Bucket,
        prefix: Prefix,
        file_name: Image.name,
        file: Image,
        on_progress: OnProgress,
    });

    return [uploader.get("ResultFile"), uploader.get("Error")];
}
