import CreateGUID from 'packages/helpers/CreateGUID';
import { connect, NKeyAuth, NatsConnection, JSONCodec, Subscription } from 'packages/nats.ws/nats';
import { Message } from './model';

const jc = JSONCodec();

export type AuthResolver = (nonce: string) => Promise<NKeyAuth>;
export type ResolveData = NKeyAuth;
export type { Subscription };

export type { NatsConnection as Connection };

export async function CreateConnection(server: string, user: string, resolver: AuthResolver) {
    //console.log(1111);
    return await connect( {
        reconnect:              true,
        waitOnFirstConnect:     false,
        maxReconnectAttempts:   4,
        servers: 				[ server ],
        async_authenticator:    async (nonce) => {
            return await resolver(nonce || "");
        },
        name: 					`FE conn for user: ${user}`
    });
}

export function Publish<M extends object>(nc: NatsConnection, subject: string, msg: Message<M>) {
    //console.log("Publish ", subject, msg);
    nc.publish(subject, jc.encode(msg))
}

export async function Request<M extends object, R extends object | void>(nc: NatsConnection, subject: string, msg: Message<M>):Promise<Message<R>> {
    return new Promise<Message<R>>(async (resolve, reject) => {
        //console.log("Request ", subject, msg);

        // bug in nats.ws, if noMux - timeout doesn't work
        const timer = setTimeout(() => {
            const paylod:any = null;
            resolve({
                marker:             msg.marker,
                context:            msg.context,
                body: {
                    status_code:    1,
                    status_text:    "TIMEOUT",
                    payload:        paylod,
                }
            })
        }, 3000);

        try {
            const res = await nc.request(subject, jc.encode(msg), {
                timeout: 3000,
                noMux:   true,
                reply:   subject.substring(32, subject.length) + CreateGUID(),
            });
            clearTimeout(timer);
            const jcr = JSONCodec<Message<R>>();
            //console.log("Response ", subject, msg);
            resolve(jcr.decode(res.data))
        } catch (error) {
            clearTimeout(timer);
            const paylod:any = null;
            resolve({
                marker:             msg.marker,
                context:            msg.context,
                body: {
                    status_code:    1,
                    status_text:    error.code,
                    payload:        paylod,
                }
            })
        }
    })
}

export function Subscribe<M extends object>(nc: NatsConnection, subject: string, callback: (msg: Message<M>, reply?: string) => void):Subscription {
    const res = nc.subscribe(subject, {
        callback: (err, msg) => {
            const jcr = JSONCodec<Message<M>>();
            //console.log("Sub  ", subject, msg.reply, err);
            callback(jcr.decode(msg.data), msg.reply)
        }
    });

    return res;
}

export function Close(nc: NatsConnection):void {
    nc.close();
}