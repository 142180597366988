import { css, CreateScheet } from "packages/aphrodite/aphrodite";
import { OpenPage } from "packages/history/history";
import { SmoothSteper, SmoothSteperItem } from "packages/motion/SmoothStepper.react";
import { LightSignIn, LightSignUp, LightVerification, LightResetPassword } from "src/themes/authentication";
import { Primary } from "src/themes/colors";
import { FormStyles } from "src/themes/form";
import ResetPasswordReact from "./modules/ResetPassword.react";
import SignIn from "./modules/SignIn.react";
import SignUp from "./modules/SignUp.react";
import Verification from "./modules/Verification.react";

const Steps:Record<string, number> = {
    "sign-in":          0,
    "create-account":   1,
    "verification":     2,
    "password_reset":   3,
}

export default function AuthenticationStepper({ step, source_url }:{ step: keyof typeof Steps, source_url?: string}) {
    return (
        <SmoothSteper {...{ active: Steps[step] || 0, duration: 500, motion: ["fade"], grow: true }}>
            <SmoothSteperItem>
                <h1 className={ css(FormStyles.title) }>Sign in</h1>
                <SignIn {...{ theme: LightSignIn, source_url }} />
                <footer className={ css(Styles.footer) }>
                    Don't have an account? <a {...{
                        className:  css(Styles.footer_link),
                        href:       "/create-account",
                        onClick:    OpenPage("create-account"),
                    }}>Create account</a>
                </footer>
            </SmoothSteperItem>
            <SmoothSteperItem>
                <h1 className={ css(FormStyles.title) }>Create your account</h1>
                <SignUp {...{ theme: LightSignUp }} />
                <footer className={ css(Styles.footer) }>
                    Have an account? <a {...{
                        className:  css(Styles.footer_link),
                        href:       "/sign-in",
                        onClick:    OpenPage("sign-in"),
                    }}>Sign in</a>
                </footer>
            </SmoothSteperItem>
            <SmoothSteperItem>
                <h1 className={ css(FormStyles.title) }>Enter verification code</h1>
                <Verification {...{ theme: LightVerification, source_url }} />
            </SmoothSteperItem>
            <SmoothSteperItem>
                <h1 className={ css(FormStyles.title) }>Reset password</h1>
                <ResetPasswordReact {...{ theme: LightResetPassword }} />
            </SmoothSteperItem>
        </SmoothSteper>
    )
}

const Styles = CreateScheet({
    footer: {
        paddingTop:     30,
        fontSize:       14,
        textAlign:      "center",
    },
    footer_link: {
        fontWeight:     500,
        textDecoration: "none",
        color:          Primary.default,
    }
});