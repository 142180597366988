import { history, OnHistoryChange } from "packages/history/history";
import Renderer from "packages/lightbox/ModalRoot.react";
import {
    listenOnSessionChanges, listenOnSignIn,
    listenOnSignOut, LoadSession,
    unlistenOnSessionChanges, unlistenOnSignIn,
    unlistenOnSignOut
} from "packages/session/session";
import ToastContainer from "packages/toasts/Container.react";
import { Fragment, useEffect, useState } from "react";
import { InitConnection } from "src/runtime/pubsub/server";
import { bindOnPersonal, unbindOnPersonal } from '../runtime/pubsub/personal';
import RouterApp from "./router.react";

export default function App() {
    const [ Loading, SetLoading ] = useState(true);
    const [ Reload, SetReload ] = useState(false);

    useEffect(() => {
        async function init() {
            try {
                await LoadSession();
                const res = await InitConnection();
                if (res !== null) {
                    console.log(res);
                }
                SetLoading(false);

                OnHistoryChange(history.location, true)
            } catch (error) {
                console.log(error);
            }
        }

        init();
    }, []);

    function onSessionChange() {
        SetReload(!Reload);
    }

    useEffect(() => {
        bindOnPersonal("session_changes", onSessionChange);

        listenOnSessionChanges(onSessionChange)
        listenOnSignIn(onSessionChange);
        listenOnSignOut(onSessionChange);

        return () => {
            unbindOnPersonal("session_changes", onSessionChange);

            unlistenOnSessionChanges(onSessionChange);
            unlistenOnSignIn(onSessionChange);
            unlistenOnSignOut(onSessionChange);
        }
    });

    if (Loading) {
        return null;
    }

    return (
        <Fragment>
            <Renderer />
            <ToastContainer />
            <RouterApp />
        </Fragment>
    )
}