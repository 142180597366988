import * as r from "runtypes";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { patch } from "packages/rest/api";

export default async function VerifyRequestedEmail(code:string) {
    const results = await patch<true>({ 
        url: "users/emails", 
        body: {
            code,
        }
    });

    if (results[1] !== null && results[1].code === 4) {
        window.location.href = "/"
    }

    return CheckResultRuntime(r.Literal(true), results);
}