import * as runtypes from "runtypes";
import { Record as IRecord, OrderedMap } from "immutable";
import { File, FileRuntime } from "packages/files/model";

export type config = {
    bucket: string;
    prefix?: string;
    file_name: string;
    file: Blob;
    part_size?: number;
    on_progress?: (uploader: Uploader) => void;
};

export type UploaderConfig = {
    GUID: string;
    Bucket: string;
    Prefix: string;
    File: Blob;
    FileName: string;
    OFN: string;
    S3Key: string;
    UploadID: string;
    Progress: number;
    PartSize: number;
    Parts: OrderedMap<number, UploaderPart>;
    Result: number | null;
    ResultFile: File | null;
    Error: Error | null;
    OnProgress: ((uploader: Uploader) => void) | undefined;
};

export const UploaderFactory = IRecord<UploaderConfig>({
    GUID: "",
    Bucket: "",
    Prefix: "",
    File: new Blob(),
    FileName: "",
    OFN: "",
    S3Key: "",
    UploadID: "",
    Progress: 0,
    PartSize: 6 * 1024 * 1024,
    Parts: OrderedMap<number, UploaderPart>(),
    Result: null,
    ResultFile: null,
    Error: null,
    OnProgress: undefined,
});

export type Uploader = IRecord<UploaderConfig>;

export type UploaderPartConfig = {
    Blob: Blob;
    PartNumber: number;
    ETag: string;
    Progress: number;
    IsProcessing: boolean;
    IsFinishing: boolean;
    IsAborted: boolean;
    Error: Error | null;
};

export const UploaderPartFactory = IRecord<UploaderPartConfig>({
    Blob: new Blob(),
    PartNumber: 0,
    ETag: "",
    Progress: 0,
    IsProcessing: false,
    IsFinishing: false,
    IsAborted: false,
    Error: null,
});

export type UploaderPart = IRecord<UploaderPartConfig>;

export type ErrorConfig = {
    code: number;
    text: string;
};

export const ErrorFactory = IRecord<ErrorConfig>({
    code: 0,
    text: "",
});

export type Error = IRecord<ErrorConfig>;

export const SignatureResponseRuntime = runtypes.Record({
    request: runtypes.String,
});

export type SignatureResponse = runtypes.Static<
    typeof SignatureResponseRuntime
>;

export const SignedInitResponseRuntime = runtypes.Record({
    request: runtypes.String,
    s3_key: runtypes.String,
});

export type SignedInitResponse = runtypes.Static<
    typeof SignedInitResponseRuntime
>;

export const CompleteResponseRuntime = runtypes.Record({
    file_id: runtypes.Number,
    file: FileRuntime,
});

export type CompleteResponse = runtypes.Static<typeof CompleteResponseRuntime>;
