import * as runtypes from "runtypes";
import CreatePolicy from "packages/policies/CreatePolicy";
import { FileRuntime } from "packages/files/model";

export const SessionUserRuntime = runtypes.Record({
    guid: runtypes.String,
    primary_email: runtypes.String.Or(runtypes.Null),
    requested_email: runtypes.String.Or(runtypes.Null),
    primary_phone: runtypes.String.Or(runtypes.Null),
    requested_phone: runtypes.String.Or(runtypes.Null),
    full_name: runtypes.String,
    company_name: runtypes.String,
    company_url: runtypes.String,
    company_logo: FileRuntime.Or(runtypes.Null),
});

export const SessionExtraRuntime = runtypes.Record({
    has_experts: runtypes.Boolean,
});

export const SessionPoliceisRuntime = CreatePolicy(
    "can_sign_in",
    "can_sign_up",
    "can_sign_out",
    "can_view_console",
    "can_use_editor"
);

export const SessionRuntime = runtypes.Record({
    user: SessionUserRuntime,
    extra: SessionExtraRuntime,
    policies: SessionPoliceisRuntime,
});

export type SessionUser = runtypes.Static<typeof SessionUserRuntime>;
export type SessionExtra = runtypes.Static<typeof SessionExtraRuntime>;
export type SessionPolicies = runtypes.Static<typeof SessionPoliceisRuntime>;
export type SessionRaw = runtypes.Static<typeof SessionRuntime>;
export type Session = runtypes.Static<typeof SessionRuntime> & {
    Reload: () => void;
    UpdateUserFields: (data: Partial<SessionUser>) => void;
};
