import React from 'react';
import ReactDOM from 'react-dom';
import App from './jsx/app.react';
import '../css/animations.css';
import '../css/base.css';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
