import { CSSProperties } from 'aphrodite';

export type CircleProgressTheme = {
    color:          CSSProperties["color"] | Array<{offset: number, color: string | undefined}>
}

export type CircleProgressLayout = {
    thickness?:     number
}

export default function CircleProgress({
    progress,
    size,
    theme,
    layout,
}: {
    theme:          CircleProgressTheme
    size:           number
    progress:       number
    layout?:        CircleProgressLayout
}) {
    const color = typeof theme.color === "string" ? theme.color : "url(#prg)";
    const thickness = layout && layout.thickness ? layout.thickness : 4;
    const half_size = size / 2;
    const margin = thickness / 2;
    const C = (half_size - margin)*2*3.14;
    progress = C * progress / 100;


    return (
        <svg {...{
            viewBox:    `0 0 ${size} ${size}`,
            width:      size,
            height:     size,
        }}>
            <defs>
                <linearGradient id="prg">
                    { Array.isArray(theme.color) && theme.color.map((color, i) => (
                        <stop key={i} offset={ `${color.offset}%` } stopColor={ color.color } />
                    )) }
                </linearGradient>
            </defs>
            <path {...{
                fill:               "none",
                strokeLinecap:      "round",
                strokeWidth:        thickness,
                stroke:             color,
                strokeDasharray:    `${progress},${C}`,
                d: `
                    M ${half_size} ${margin}
                    A 1 1 0 0 1 ${half_size} ${size - margin}
                    A 1 1 0 0 1 ${half_size} ${margin}
                `
            }} />
        </svg>
    );
}