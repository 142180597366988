import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
// models
import { File as ExternalFile } from "./model";
// icons
import { ReactComponent as UploadFileIcon } from "src/icons/upload-file.svg";
import CircleProgress from "packages/progress/Circle.react";
// functions
import { UploadImage } from "packages/s3upload/image";
import { showLightbox } from "packages/lightbox/lightbox";
// styles
import { CreateScheet, css } from "aphrodite";
import { LightTheme } from "src/themes/lightbox";
import { CircleRedPurple } from "src/themes/progress";
import Cropper from "./Cropper.react";

interface props {
    size?: number;
    aspect?: number;
    file?: ExternalFile;
    OnUpload: (file_id: ExternalFile) => void;
}

export default function ImageUpload({ OnUpload, file, aspect, size }: props) {
    const fileRef = useRef<HTMLInputElement>(null);
    const [Err, SetErr] = useState(false);
    const [Progress, SetProgress] = useState(0);
    const [CroppedImageSource, SetCroppedImageSource] = useState<string>("");

    const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file_name = e.target.files[0].name;

            const reader = new FileReader();
            reader.addEventListener("load", () => {
                if (reader.result && typeof reader.result === "string") {
                    if (fileRef.current) {
                        fileRef.current.value = "";
                    }

                    showLightbox<{
                        src: string;
                        aspect?: number;
                        file_name: string;
                        onSave: (src: File) => void;
                    }>({
                        selector: "image-crop",
                        theme: LightTheme,
                        Component: Cropper,
                        styles: {
                            wrapper: Styles.cropper,
                        },
                        content: {
                            src: reader.result,
                            aspect: aspect,
                            onSave: onSaveCroppedImage,
                            file_name: file_name,
                        },
                    });
                }
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    function onSaveCroppedImage(file: File) {
        let fileUrl = "";
        window.URL.revokeObjectURL(fileUrl);
        SetCroppedImageSource(window.URL.createObjectURL(file));

        uploadFile(file);
    }

    function OnClick() {
        if (Progress === 0) {
            SetErr(false);
            fileRef.current?.click();
        }
    }

    async function uploadFile(file: File) {
        let toastID = toast("Uploading image...", {
            progress: 0,
            autoClose: false,
        });

        const [image, error] = await UploadImage(file, (u) => {
            toast.update(toastID, {
                progress: u.get("Progress") / 100,
                autoClose: false,
            });
            SetProgress(u.get("Progress"));
        });

        if (error !== null) {
            SetErr(true);
            SetProgress(0);
        } else if (image !== null) {
            SetProgress(0);
            OnUpload(image);
        }
    }

    const source =
        file && file.sources
            ? file.sources.jpeg || file.sources.png
            : undefined;
    size = size || 200;

    return (
        <button
            {...{
                className: css(
                    Styles.block,
                    Progress > 0 && Progress < 100 && Styles.activeBlock,
                    !CroppedImageSource && !file && Styles.blockShow
                ),
                style: {
                    width: aspect ? size * aspect : size,
                    height: size,
                },
                onClick: OnClick,
            }}
        >
            {source ? (
                <img
                    className={css(Styles.image)}
                    src={source.url}
                    alt="profile"
                />
            ) : CroppedImageSource ? (
                <img
                    className={css(Styles.image)}
                    src={CroppedImageSource}
                    alt="profile"
                />
            ) : null}
            <div className={`${css(Styles.iconOverlay)} icon-overlay`} />
            <div
                className={`${css(Styles.icon)}${
                    Progress > 0 && Progress < 100 ? "" : " upload-img-icon"
                }`}
            >
                {Progress > 0 && Progress < 100 ? (
                    <div className={css(Styles.circleProgress)}>
                        <CircleProgress
                            progress={Progress}
                            size={60}
                            theme={CircleRedPurple}
                        />
                    </div>
                ) : null}
                {Progress > 0 && Progress < 100 ? (
                    `${Progress}%`
                ) : (
                    <div className={css(Styles.uploadIconBlock)}>
                        <UploadFileIcon width="18" height="18" />
                        <span>Upload</span>
                    </div>
                )}
            </div>
            {Err ? (
                <div className={css(Styles.err)}>please try again</div>
            ) : null}
            <input
                {...{
                    style: {
                        display: "none",
                    },
                    type: "file",
                    accept: ".tif, .tiff, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .gif",
                    onChange: onSelectFile,
                    ref: fileRef,
                }}
            />
        </button>
    );
}

const Styles = CreateScheet({
    block: {
        backgroundColor: "#C4C4C4",
        borderRadius: "10px",
        position: "relative",
        cursor: "pointer",
        display: "block",
        border: "none",
        outline: "none",
        color: "white",
        padding: 0,
        ":nth-child(1n) .upload-img-icon": {
            display: "none",
        },
        ":hover": {
            ":nth-child(1n) .upload-img-icon": {
                display: "flex",
            },
            ":nth-child(1n) .icon-overlay": {
                display: "block",
            },
        },
    },
    blockShow: {
        display: "block",
        ":nth-child(1n) .upload-img-icon": {
            display: "flex",
        },
        ":nth-child(1n) .icon-overlay": {
            display: "block",
        },
    },
    activeBlock: {
        ":nth-child(1n) .icon-overlay": {
            display: "block",
        },
    },
    iconOverlay: {
        opacity: 0.8,
        display: "none",
        background: "#000",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: 10,
    },
    icon: {
        width: "60px",
        height: "60px",
        borderRadius: "30px",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: "calc(50% - 30px)",
        left: "calc(50% - 30px)",
        fontWeight: 700,
        fontSize: "1rem",
    },
    uploadIconBlock: {
        color: "white",
        fontWeight: 700,
        fontSize: "1rem",
        lineHeight: "1.2193rem",
        ":nth-child(1n) svg": {
            stroke: "white",
            marginBottom: 5,
        },
    },
    image: {
        width: "100%",
        height: "100%",
        borderRadius: "10px",
    },
    circleProgress: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    err: {
        position: "absolute",
        bottom: "0px",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "center",
        color: "rgb(255 200 200)",
        left: "0px",
        right: "0px",
        background: "#707070",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        height: "25px",
        lineHeight: "25px",
    },
    cropper: {
        width: "auto",
    },
});
