import { TabsTheme, TabsLayout } from 'packages/navigation/tabs.react';

export const DefaultLayout:TabsLayout = {
    list: {
        padding:            undefined,
        margin:             undefined,
    },
    tab: {
        padding:            undefined,
        margin:             undefined,
        transition:         "transform .3s, color .3s",
        bottomLineHeight:   3,
    },
};

export const Purple:TabsTheme = {
    plain: {
        color:              "#6e6b7b",
    },
    active: {
        color:              "#1f99d5",
        bottomLineColor:    "#1f99d5",
    }
}

export const Red:TabsTheme = {
    plain: {
        color:              "#6e6b7b",
    },
    active: {
        color:              "#ea5455",
        bottomLineColor:    "#ea5455",
    }
}