import { PageHeaderTheme } from "src/jsx/headers/PageHeader.theme";
import { DarkTransparent, WhiteTransparent, PrimaryFilled } from "./button";
import { DarkLogo, LightLogo } from "./logo";

export const DarkHeader:PageHeaderTheme = {
    logo:           DarkLogo,
    nav_buttons:    DarkTransparent,
    sign_in_button: PrimaryFilled,
}

export const LightHeader:PageHeaderTheme = {
    logo:           LightLogo,
    nav_buttons:    WhiteTransparent,
    sign_in_button: PrimaryFilled,
}