import { history } from 'packages/history/history';
import { GetPageMeta } from "packages/history/metatags";
import { GetQueryParam } from "packages/history/query_string";
import { Fragment, useEffect, useRef, useState } from "react";
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Page, Page404, pages, FindPage, redirects } from 'src/runtime/pages';
// components
import PageHeader from 'src/jsx/headers/PageHeader.react';
// styles
import { CreateScheet, css } from 'aphrodite';
import { DarkHeader } from 'src/themes/header';

export type SwitcherProps = {
    Routes:  Page[],
    IsRoot?: boolean,
}

export default function RouterApp() {
    return (
        <Router history={ history } >
            <Switcher {...{
                Routes: pages.filter(p => !p.parent_id),
                IsRoot: true,
            }}/>
        </Router>
    )
}
function Switcher({
    Routes, 
    IsRoot,
}:SwitcherProps) {
    const location = useLocation();
    const page = FindPage(Routes, location.pathname);

    return (
        <article className={css(Styles.page)}>
            <CSSTransition {...{
                key:            "header-animation",
                in:             !page?.hideHeader,
                timeout:        {enter: 150, exit: 150},
                classNames:     "fade",
                unmountOnExit:  true,
            }}>
                <PageHeader {...{ active: page?.id, theme: DarkHeader, showSearch: page?.showSearch }} />
            </CSSTransition>
            <div className={css(Styles.main)}>
                <TransitionGroup className={css(Styles.transition)}>
                    <CSSTransition {...{
                        key:            page?.id,
                        in:             true,
                        timeout:        {enter: 150, exit: 150},
                        classNames:     "fade",
                        unmountOnExit:  true,
                    }}>
                        <Switch location={ location }>
                            {IsRoot && redirects.map(Rdr => (
                                <Redirect key={Rdr.from} {...Rdr}/>
                            ))}

                            {Routes.map(Page => (
                                <Route {...{
                                    key:        typeof Page.path === "string" ? Page.path : Page.path.join("-"),
                                    path: 		typeof Page.path === "string" ? `/${Page.path}` : Page.path.map(p => `/${p}`),
                                    exact:      Page.exact,
                                }}>
                                    <PageLoader {...{ Page, IsRoot: IsRoot }} />
                                </Route>
                            ))}
                            {IsRoot &&
                                <Route>
                                    <PageLoader {...{
                                        Page: Page404,
                                    }} />
                                </Route>
                            }
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </article>
    )
}

function PageLoader({ Page, IsRoot }: { 
    Page: Page,  IsRoot?: boolean
}) {
    const location = useLocation();
    const [ Meta, SetMeta ] = useState<any | "error" | "loading">("loading");
    const Pathname = useRef(location.pathname);
    const MetaCheck = useRef(Page.meta);
    const Component = useRef(Page.Component);
    const redirectSource = useRef<string>();

    useEffect(() => {
        Pathname.current = location.pathname;
        const rdrSource = GetQueryParam("rdr_source");
        if (typeof rdrSource[0] === "string") {
            redirectSource.current = rdrSource[0]
        } else {
            redirectSource.current = undefined;
        }
        async function load() {
            try {
                const res = await GetPageMeta<any>(Pathname.current);
                if (res[1] !== null) {
                    if (res[1].code === 404) {
                        Component.current = Page404.Component;
                    }
                    SetMeta("error");
                } else {
                    MetaCheck.current.check(res[0]);
                    SetMeta(res[0]);
                }
            } catch (error) {
                SetMeta("error");
            }
        }

        load();
    }, [ IsRoot, location.pathname ]);

    const PageComponent = Component.current;
    const ChildRoutes = pages.filter(p => p.parent_id === Page.id);

    return (
        <div className={ css(Styles.wrapper) }>
            {(Meta !== "loading" || IsRoot) && 
                <Fragment>
                    <PageComponent {...{
                        id: Page.id,
                        meta: {
                            is_loading:         Meta === "loading",
                            is_error:           Meta === "error",
                            payload:            Meta,
                            redirect_source:    redirectSource.current,
                        },
                        childRoutes: ChildRoutes,
                    }} />
                    {ChildRoutes.length > 0 &&
                        <Switcher {...{
                            Routes: ChildRoutes,
                        }}/>
                    }
                </Fragment>
            }
        </div>
    )
}

const Styles = CreateScheet({
    page: {
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
    },
    main: {
        flexGrow: 1,
    },
    transition: {
        width: "100%",
        position: "relative",
    },
    wrapper: {
        height: "100%",
    }
});