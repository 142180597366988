import { CreateScheetWithTheme, css, useTheme } from "packages/aphrodite/aphrodite";
import useOutsideClick from "packages/hooks/useOutsideClick";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Layout } from "./dropdownBase.model";
import { fadeAnimation } from './dropdownBase.css';
import { CSSTransition } from "react-transition-group";

const DropDownAnimationTime = 150;

export interface DropdownBaseFunctions {
    toggleDropElement: () => void,
}

const DropdownBase = ({
    header,
    dropElement,
    disabled,
    animation,
    layout,
    preventCloseOnClick,
}:{
    header:         () => ReactNode,
    dropElement:    (functions:DropdownBaseFunctions) => ReactNode,
    disabled?:      boolean,
    animation?:     "fade",
    layout?:        Layout,
    preventCloseOnClick?: boolean,
}) => {
    const Styles = useTheme(layout, StylesWithTheme);

    const wrapperRef = useRef<HTMLDivElement>(null)
    const [ show, setShow ] = useState(false)

    function toggleDropElement() {
        if (!disabled) {
            setShow(!show)
        }
    }

    useEffect(() => {
        if (disabled && show) {
            setShow(false)
        }
    }, [ show, disabled ])

    const escapeHandler = useCallback((e:KeyboardEvent) => {
        if (e.code === "Escape" && show) {
            setShow(false)
        }
    }, [ show ])

    useOutsideClick(wrapperRef, () => {
        if (show) {
            setShow(false)
        }
    })

    useEffect(() => {
        document.addEventListener("keydown", escapeHandler)
        
        return () => {
            document.removeEventListener("keydown", escapeHandler)
        }
    })

    return (
        <div {...{
            className: css(
                Styles.wrapper, 
                disabled && Styles.disabled, 
                animation === "fade" && fadeAnimation(DropDownAnimationTime),
                show && Styles.wrapperShowed,
            ),
            onClick:   () => {
                if (!show || !preventCloseOnClick) {
                    toggleDropElement()
                }
            },
            ref:       wrapperRef,
        }}>
            {header()}
            <CSSTransition {...{
                classNames:    animation === "fade" ? `dropdown-fade` : undefined,
                timeout:       animation === undefined ? 0 : DropDownAnimationTime,
                in:            show,
                mountOnEnter:  true,
                unmountOnExit: true,
            }}>
                {dropElement({
                    toggleDropElement,
                })}
            </CSSTransition>
        </div>
    );
}

export default DropdownBase;

const StylesWithTheme = CreateScheetWithTheme((layout?: Layout) => { return {
    wrapper: {
        position: "relative",
        cursor:   "pointer",
        margin:   layout?.wrapper?.margin ? layout.wrapper.margin : 0,
        zIndex:   layout?.wrapper?.zIndex !== undefined ? layout.wrapper.zIndex : 2,
        width:    layout?.wrapper?.width ? layout.wrapper.width : undefined,
    },
    wrapperShowed: {
        zIndex: layout?.wrapper?.zIndex !== undefined ? layout.wrapper.zIndex : 3,
    },
    disabled: {
        cursor: "default",
        opacity: 0.8,
    },
}})