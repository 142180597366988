import { useState, useRef } from 'react';
import ReactCrop, {Crop} from "react-image-crop";
import { ComponentProps, Lightbox, Body, Footer } from 'packages/lightbox/lightbox';
// styles
import { CreateScheet, css } from 'aphrodite';
import "react-image-crop/dist/ReactCrop.css";
import { DarkTransparent, MakeButton, PrimaryFilled } from 'src/themes/button';

export default function Cropper({ content, close }:ComponentProps<{
    src:        string
    aspect?:    number
    file_name:  string
    onSave:     (src: File) => void,
}>) {
    const imageRef = useRef<HTMLImageElement>();
    const [ crop, setCrop ] = useState<Crop>({
        unit:       "%",
        height:     30,
        width:      30,
        aspect:     content.aspect,
        x:          0,
        y:          0,
    });
    const [ CroppedImage, SetCroppedImage ] = useState<File | null>(null);
            
    function OnLoad(image: HTMLImageElement) {
        imageRef.current = image;
    }
            
    function OnChange(crop: Crop) {
        setCrop(crop)
    }
            
    function OnComplete(crop: Crop) {
        MakeClientCrop(crop);
    }
            
    async function MakeClientCrop(crop: Crop) {
        if (imageRef && imageRef.current && crop.width && crop.height) {
            const CroppedImageBlob = await GetCroppedImg(
                imageRef.current,
                crop,
            );
            
            SetCroppedImage(CroppedImageBlob);
        }
    }
            
    async function GetCroppedImg(image: HTMLImageElement, crop: Crop):Promise<File | null> {
        if (!crop.width || !crop.height || typeof crop.x !== "number" || typeof crop.y !== "number") {
            return null;
        }
            
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return null;
        }
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }

                let b:any = blob;
                b.lastModifiedDate = new Date();
                b.name = content.file_name;

                resolve(b);
            }, 'image/png');
        });
    }

    function OnClickSave() {
        if (CroppedImage) {
            close();
            content.onSave(CroppedImage);
        }
    }

    return (
        <Lightbox>
            <Body>
                <ReactCrop {...{
                    minWidth:       30,
                    minHeight:      30,
                    className:      css(Styles.cropper),
                    src:            content.src,
                    crop:           crop,
                    onImageLoaded:  OnLoad,
                    onChange:       OnChange,
                    onComplete:     OnComplete,
                }} />
            </Body>
            <Footer styles={ Styles.footer }>
                <button {...{
                    className: css(MakeButton(DarkTransparent)),
                    onClick: close,
                }}>Close</button>
                <button {...{
                    className: css(MakeButton(PrimaryFilled), Styles.SaveButton),
                    onClick: OnClickSave,
                }}>Save</button>
            </Footer>
        </Lightbox>
    );
}

const Styles = CreateScheet({
    footer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    SaveButton: {
        marginLeft: 20,
    },
    cropper: {
        maxWidth: 400,
    }
})
