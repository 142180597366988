
/* Use lazy loading for huge pages please, just wrap your page into React.Suspense */
/* keep in mind that pages w/ lazy loading may brake page switch animation */
/* maybe we need a flap on page level to which would be use to enable page switch animation */
import Authentication from 'src/jsx/authentication/Authentication.page.react';

import Page404React from 'src/jsx/errors/404page.react';
import { ComponentType } from 'react';
import SafeUpdate from 'packages/helpers/SafeUpdate';
import { matchPath, RedirectProps } from 'react-router-dom';
import * as runtypes from 'runtypes';
import Landing from 'src/jsx/landing/Landing.lazy.react';
import LazyFAQ from 'src/jsx/faq/faq.lazy.react';
import LazyHelp from 'src/jsx/help/help.lazy.react';
import LazyCoupon from 'src/jsx/coupons/Coupon.lazy.react';
import LazyConsole from 'src/jsx/console/console.lazy.react';
import LazyReports from 'src/jsx/reports/reports.lazy.react';
import LazyPrivacy from 'src/jsx/privacy/privacy.lazy.react';
import LazyTerms from 'src/jsx/terms/terms.lazy.react';
import LazyDebug from 'src/jsx/debug/debug.lazy.react';

export const EmptyMeta = runtypes.Null;

export type PageProps<T = null> = {
    id:                     string,
    childRoutes:            Page[],
    meta:                   {
        is_loading:         boolean
        is_error:           boolean
        payload:            T
        redirect_source?:   string
    },
}

export const pages:Page<any>[] = [];
export const redirects:RedirectProps[] =[];

export const Page404:Page = {id: "404error", path: "", exact: true, Component: Page404React, meta: EmptyMeta};

/* KEEP IN MIND THAT PAGE ADDED JUST HERE W/O Go HANDLER WILL BE SOFT 404 */
/* meta should be used to pass only very important and small amount of data e.g. access error otherwise page loading will be slow */
export const RoutesObject = {
    landing: { path: "", exact: true, Component: Landing, meta: EmptyMeta },
    auth: { path: ["sign-in", "create-account", "verification", "password_reset"], exact: true, Component: Authentication, meta: EmptyMeta, hideHeader: true },
    faq: { path: "faq", exact: true, Component: LazyFAQ, meta: EmptyMeta },
    help: { path: "help", exact: true, Component: LazyHelp, meta: EmptyMeta },
    coupons: { path: "coupons", exact: true, Component: LazyCoupon, meta: EmptyMeta, showSearch: true},
    coupon: { path: "coupons/:guid", exact: true, Component: LazyCoupon, meta: EmptyMeta },
    reports: { path: "reports", exact: true, Component: LazyReports, meta: EmptyMeta },
    privacy: { path: "privacy", exact: true, Component: LazyPrivacy, meta: EmptyMeta },
    terms: { path: "terms", exact: true, Component: LazyTerms, meta: EmptyMeta },
    console: {path:"console", exact:true, Component: LazyConsole, meta: EmptyMeta},
    debug: {path:"debug", exact:true, Component: LazyDebug, meta:EmptyMeta},
}

type PageEntry = Omit<Page, "id" | "subRoutes"> & {
    subRoutes?: Record<string, PageEntry>
}

function MakePage(key: PageID, obj:PageEntry):Page {
    let page:Page = {...obj, id: key, subRoutes: []}
    if (obj.subRoutes) {
        page.subRoutes =  Object.entries(obj.subRoutes).map(entry => MakePage(entry[0] as PageID, entry[1]))
    }

    return page
}

const Routes:Page[] = Object.entries(RoutesObject).map(entry => MakePage(entry[0] as PageID, entry[1]))
AddRoutes(Routes);

export type PageID =  "404error" | keyof typeof RoutesObject;

export interface Page<T = null> {
    id:         PageID
    parent_id?: string
    path:       string | string[]
    exact:      boolean
    Component:  ComponentType<PageProps<T>>
    meta:       runtypes.Runtype
    subRoutes?: Page<T>[],
    showSearch?:boolean,
    hideHeader?:boolean,
}

export function AddRoutes<T = null>(routes:Page<T>[], parent?:Page<T>) {
    routes.forEach(route => {
        if (parent) {
            route = SafeUpdate(route, {parent_id: {$set: parent.id}})
            route = SafeUpdate(route, {path: {$set: `${parent.path}/${route.path}`}})
        }

        pages.push(route)
        if (route.subRoutes?.length) {
            AddRoutes<T>(route.subRoutes, route)
        }
    })
}

export function AddRedirect(redirect:RedirectProps) {
    redirects.push(redirect);
}

export function FindPage(Pages:Page[], path: string) {
    return Pages.find(page => {
        return matchPath(path, {
            path:   typeof page.path === "string" ? `/${page.path}` : page.path.map(p => `/${p}`),
            exact:  page.exact,
        })
    });
}