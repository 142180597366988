
import { useState, useEffect, useRef } from "react";
import { Events } from './events';
import { session, SignOut } from 'packages/session/session';
import { OpenPage } from "packages/history/history";
import useOutsideClick from "packages/hooks/useOutsideClick";
import { showLightbox } from "packages/lightbox/lightbox";
// components
import Tabs from "packages/navigation/tabs.react";
import Dropdown from "packages/dropdowns/dropdownBase.react";
import ProfileSettings from "packages/profile/Settings.react";
import Portal from "packages/portal/portal.react";
// img
import Logo from './img/rapid_mpy.png';
// icons
import { ReactComponent as MenuIcon } from 'src/icons/menu.svg';
import { ReactComponent as CrossIcon } from 'src/icons/cross.svg';
import { ReactComponent as UserIcon } from 'src/icons/user.svg';
// styles
import { css, CreateScheetWithTheme, useTheme } from 'aphrodite';
import { HeaderHeight } from 'src/themes/sizes';
import { DefaultLayout as TabsDefaultLayout, Purple as TabsPurple } from 'src/themes/tabs';
import { PageHeaderTheme } from './PageHeader.theme';
import { MakeInput, PrimaryInput } from 'src/themes/input';
import { LightTheme } from "src/themes/lightbox";
import { Dark, Primary, White, Gray } from "src/themes/colors";

export default function PageHeader({ active, theme, showSearch }: { active?: string, theme: PageHeaderTheme, showSearch?: boolean }) {
    const Styles = useTheme(theme, StylesTheme)
    const SidebarRef = useRef<HTMLDivElement>(null)

    const [ isMobile, setIsMobile ] = useState(false);
    const [ isMobileSearch, setIsMobileSearch ] = useState(false);
    const [ SearchKey ] = useState<string>("");
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", checkMobile);
        checkMobile();
        return () => {
            window.removeEventListener("resize", checkMobile);
        }
    });

    function checkMobile() {
        const winWidth = window.innerWidth;
        if (winWidth <= 750) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        if (winWidth <= 600) {
            setIsMobileSearch(true);
        } else {
            setIsMobileSearch(false);
        }
    }

    function handleSearch(e:React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            Events.emit('search-coupons', e.currentTarget.value.trim())
        }
    }

    function showProfileSettings() {
        showLightbox({
            selector:  "profile-settings",
            Component: ProfileSettings,
            theme:     LightTheme,
            content:   {}
        })
    }

    function openSidebar() {
        setShowSidebar(true);
    }

    function closeSidebar() {
        setShowSidebar(false);
    }

    useOutsideClick(SidebarRef, () => {
        if (isMobile && showSidebar) {
            setShowSidebar(false)
        }
    })

    let tabs = [
        {
            id: ['coupons', 'coupon'],
            name: 'Coupons',
            link: 'coupons',
        },
        {
            id: ['reports'],
            name: 'Reports',
            link: 'reports',
        },
        {
            id: ['help'],
            name: 'Help',
            link: 'help',
        },
    ]

    if (!session.policies.can_sign_out) {
        tabs = [
            {
                id: ['faq'],
                name: 'FAQ',
                link: 'faq',
            },
            {
                id: ['help'],
                name: 'Help',
                link: 'help',
            },
            /*{
                id: ['help'],
                name: 'Download app',
                link: 'help',
            },*/
        ]
    }

    return (
        <>
        <header className={ css(Styles.header) }>
            <div className={css(Styles.logo)}>
                <img src={Logo} height="50" onClick={OpenPage('')} alt="" />
            </div>
            {!isMobile ? (
                <div className={css(Styles.tabs)}>
                    <Tabs {...{
                        layout: {
                            ...TabsDefaultLayout,
                            list: {
                                ...TabsDefaultLayout.list,
                                margin: "20px 0 20px 0",
                            }
                        },
                        theme: TabsPurple,
                        active: active ? tabs.findIndex(x => x.id.includes(active)) : -1
                    }}>
                        {tabs.map(tab => (
                            <a className={css(Styles.tab)} href={`/${tab.link}`} key={tab.link} onClick={OpenPage(tab.link)}>
                                {tab.name}
                            </a>
                        ))}
                    </Tabs>
                </div>
            ) : null}
            <div className={css(Styles.nav)}>
                {(!isMobileSearch && showSearch) && (
                <input {...{
                    className: css(MakeInput(PrimaryInput, {
                        padding: "7px 10px",
                        height:  36,
                        borderRadius: 5,
                    }), Styles.searchInput),
                    placeholder:  "Search coupons",
                    onKeyDown:    handleSearch,
                    defaultValue: SearchKey,
                    autoFocus:    SearchKey.length > 0,
                }}/>
                )}
                {(!isMobile && session.policies.can_sign_up && !session.policies.can_sign_out) && (
                    <a className={css(Styles.loginLink)} href="/create-account" onClick={OpenPage("create-account")}>Create account</a>
                )}
                {(!isMobile && session.policies.can_sign_in) && (
                    <a className={css(Styles.loginLink)} href="/sign-in" onClick={OpenPage("sign-in")}>Sign in</a>
                )}
                {session.policies.can_sign_out && (
                    <Dropdown {...{
                        animation: "fade",
                        header:    () => (
                            <UserIcon className={css(Styles.avatar)} />
                        ),
                        dropElement: () => (
                            <ul className={ css(Styles.profileActions) }>
                                <li {...{
                                    className: css(Styles.profileAction),
                                    onClick:   showProfileSettings,
                                }}>
                                    Profile settings
                                </li>
                                <li {...{
                                    className: css(Styles.profileAction),
                                    onClick: OpenPage('reports'),
                                }}>
                                    Reports
                                </li>
                                <li className={css(Styles.profileDivider)} />
                                <li {...{
                                    className: css(Styles.profileAction),
                                    onClick: SignOut
                                }}>
                                    Sign out
                                </li>
                            </ul>
                        ),
                    }}/>
                )}
                {isMobile ? (
                    <div className={css(Styles.menuLink)}>
                        <MenuIcon className={css(Styles.menuLinkIcon)} onClick={openSidebar} />
                    </div>
                ) : null}
            </div>
        </header>
        {isMobileSearch && showSearch ?
            <div className={css(Styles.search)}>
                <input {...{
                    className: css(MakeInput(PrimaryInput, {
                        padding: "7px 10px",
                        height:  36,
                        borderRadius: 5,
                    }), Styles.searchInput),
                    placeholder:  "Search coupons",
                    onKeyDown:    handleSearch,
                    defaultValue: SearchKey,
                    autoFocus:    SearchKey.length > 0,
                }}/>
            </div>
        : null }
        {isMobile && (
        <Portal>
            <div className={css(Styles.sidebar, (isMobile && showSidebar) && Styles.sidebarActive)} ref={SidebarRef}>
                <div className={css(Styles.closeMenu)}>
                    <CrossIcon className={css(Styles.closeMenuIcon)} onClick={closeSidebar} />
                </div>
                <ul className={css(Styles.sidebarMenu)}>
                    {session.policies.can_sign_out && (
                    <li className={css(Styles.sidebarMenuItem)}>
                        <a className={css(Styles.menuItemLink)} href="/coupons" onClick={OpenPage("coupons")}>Coupons</a>
                    </li>
                    )}
                    {session.policies.can_sign_out && (
                    <li className={css(Styles.sidebarMenuItem)}>
                        <a className={css(Styles.menuItemLink)} href="/reports" onClick={OpenPage("reports")}>Reports</a>
                    </li>
                    )}
                    {!session.policies.can_sign_out && (
                    <li className={css(Styles.sidebarMenuItem)}>
                        <a className={css(Styles.menuItemLink)} href="/faq" onClick={OpenPage("faq")}>FAQ</a>
                    </li>
                    )}
                    <li className={css(Styles.sidebarMenuItem)}>
                        <a className={css(Styles.menuItemLink)} href="/help" onClick={OpenPage("help")}>Help</a>
                    </li>
                    {/*!session.policies.can_sign_out && (
                    <li className={css(Styles.sidebarMenuItem)}>
                        <a className={css(Styles.menuItemLink)} href="/faq" onClick={OpenPage("faq")}>Download app</a>
                    </li>
                    )*/}
                    {(session.policies.can_sign_up && !session.policies.can_sign_out) && (
                        <li className={css(Styles.sidebarMenuItem)}>
                            <a className={css(Styles.menuItemLink)} href="/create-account" onClick={OpenPage("create-account")}>Create account</a>
                        </li>
                    )}
                    {session.policies.can_sign_in && (
                        <li className={css(Styles.sidebarMenuItem)}>
                            <a className={css(Styles.menuItemLink)} href="/sign-in" onClick={OpenPage("sign-in")}>Sign in</a>
                        </li>
                    )}
                </ul>
            </div>
        </Portal>
        )}
        </>
    );
}

const StylesTheme = CreateScheetWithTheme((theme?: PageHeaderTheme) => { return {
    header: {
        position: "relative",
        display:    "flex",
        alignItems: "center",
        height:     HeaderHeight,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 30,
        paddingLeft: 30,
    },
    logo: {
        marginRight: 30,
        flexGrow: 0,
        ':nth-child(1n) img': {
            cursor: "pointer",
        },
    },
    tabs: {
        flexGrow: 1,
    },
    nav: {
        flexGrow:       1,
        display:        'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    sidebarMenu: {
        marginTop: 30,
    },
    sidebarMenuItem: {
        margin: "10px 0",
        listStyle: "none",
        whiteSpace: "nowrap",
    },
    menuItem: {
        margin: "0 10px",
        listStyle: "none",
        whiteSpace: "nowrap",
    },
    menuItemLink: {
        fontSize: 18,
        fontWeight: 700,
        color: Dark.default,
        textDecoration: "none",
        ":hover": {
            textDecoration: "underline",
        },
        "@media(max-width: 750px)": {
            fontSize: 14,
        }
    },
    loginLink: {
        marginLeft: 10,
        fontSize: 14,
        fontWeight: 600,
        color: Dark.default,
        textDecoration: "none",
        ":hover": {
            textDecoration: "underline",
        }
    },
    menuLink: {
        marginLeft: 10,
    },
    menuLinkIcon: {
        fill: Dark.default,
        cursor: "pointer",
    },
    closeMenu: {
        position: "absolute",
        top: 10,
        right: 10,
        zIndex: 2,
    },
    closeMenuIcon: {
        fill: Dark.default,
        cursor: "pointer",
    },
    search: {
        flexGrow:       1,
        display:        'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 30,
        "@media(max-width: 600px)": {
            margin: "0 30px 0 0",
            justifyContent: 'flex-end',
            flexGrow: 0,
        }
    },
    searchInput: {
        width: 200,
    },
    avatar: {
        cursor : "pointer",
        height: 36,
        width: 36,
        marginLeft: 20,
        fill: Primary.default,
    },
    sidebar: {
        boxShadow: "2px 0 12px 0 rgba(3,7,16,0.08)",
        padding: `16px 0 0 0`,
        backgroundColor: "white",
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 10,
        width: 200,
        transform: `translateX(200px)`,
        transition: `transform 0.2s ease-out`,
    },
    sidebarActive: {
        transform: `translateX(0)`,
    },
    tab: {
        display: "block",
        padding: "10px 20px",
        color: Dark.default,
        textDecoration: "none",
    },
    profileActions: {
        position: "absolute",
        top: "100%",
        right: 0,
        minWidth: "100%",
        margin: 0,
        padding: "10px 0",
        fontSize: 14,
        listStyle: "none",
        color: Dark.default,
        background: White.default,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
        userSelect: "none",
    },
    profileAction: {
        padding: "5px 10px",
        whiteSpace: "nowrap",
        ":hover": {
            backgroundColor: Gray.default,
        }
    },
    profileDivider: {
        width: "100%",
        height: 1,
        margin: "5px 0",
        backgroundColor: Gray.default,
    }
}});