import { history } from 'packages/history/history';

export function GetQueryParam(param:string):[string | boolean,  string[] | null] {
    const data = (new URL(window.location.href)).searchParams.get(param);
    if (typeof data === "string") {
        return [ typeof data === "string" ? data : true, null ]
    } else {
        return [ false, null ];
    }
};

export function GetQueryParams(params:string[]):Array<string | null> {
    const res:Array<string | null> = [];
    params.forEach(param => {
        let [parsed] = GetQueryParam(param);
        res.push(typeof parsed === "string" ? parsed : null);
    })

    return res;
};

export const getQuery = () => {
    if (typeof window !== 'undefined') {
        return new URLSearchParams(window.location.search);
    }

    return new URLSearchParams();
};

export const setQuery = (value: string, type?: "push") => {
    const query = getQuery();
    const queryName = value.split('=');
    queryName[1] = typeof queryName[1] === "undefined" ? "" : queryName[1];
    query.set(queryName[0], queryName[1]);

    if (typeof window !== 'undefined') {
        const {pathname} = window.location;
        const newUrl = `${pathname}?${query.toString()}`;
        if (type === 'push') {
            history.push(newUrl);
        } else {
            history.replace(newUrl);
        }
    }
};

export function RecordToQuerystring(obj:Record<string, any>):string {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}