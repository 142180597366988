import { css, CreateScheetWithTheme, useTheme } from "aphrodite";
import { LoadButton } from "src/themes/button";
import { MakeInput } from "src/themes/input";
import { SignUpTheme } from './SignUp.theme'
import { useForm } from "react-hook-form";
import { useState } from "react";
import { SignUp } from "packages/session/session";
import { CreateUserRequest } from "packages/session/CreateUserAPI";
import { history } from "packages/history/history";
import SmoothErrorText from "packages/motion/SmoothErrorText.react";
import { FormStyles } from "src/themes/form";
import { ReactComponent as Eye } from 'src/icons/eye.svg';
import { ReactComponent as EyeOff } from 'src/icons/eye_off.svg';
import { Primary } from "src/themes/colors";


export default function SignUpReact({ theme }:{ theme: SignUpTheme }) {
    const Styles = useTheme(theme, StylesWithTheme);
    const [ Loading, SetLoading ] = useState(false);
    const [showPass, setShowPass] = useState(false);

    const { register, handleSubmit, watch, setError, formState: { errors } } = useForm<CreateUserRequest>({ defaultValues: {
        email:      "",
        password:   "",
    } });
    
    const OnSubmitForm = handleSubmit(async data => {
        if (data.password.length < 6) {
            setError("password", { message: "Minimum password length is 6 symbols" });
        } else {
            SetLoading(true);
            const err = await SignUp(data);
            SetLoading(false);

            if (err !== null) {
                setError("email", { message: err.text });
            } else {
                history.push("/verification");
            }
        }
    });

    const isEmpty = watch("email").length === 0 || watch("password").length === 0 || watch("full_name").length === 0;

    return (
        <form {...{
            method:     "POST",
            className:  css(FormStyles.form),
            onSubmit:   OnSubmitForm,
        }}>
            <label {...{
                htmlFor:    "email",
                className:  css(FormStyles.label, Styles.label),
            }}>
                Email
                <SmoothErrorText className={ css(FormStyles.label_error) } motion={["fade", "swipe-right"]} >
                    { errors.email?.message }
                </SmoothErrorText>
            </label>
            <input {...{
                ...register("email", {
                    required: true,
                }),
                id:             "email",
                type:           "text",
                autoFocus:      true,
                required:       true,
                autoComplete:   "off",
                className:      css(MakeInput(theme.input, {
                    fontSize:       16,
                    width:          "100%",
                }), Styles.input)
            }} />
            <label {...{
                htmlFor:    "full_name",
                className:  css(FormStyles.label, Styles.label),
            }}>
                Full name
            </label>
            <input {...{
                ...register("full_name", {
                    required: true,
                }),
                id:             "full_name",
                type:           "text",
                autoComplete:   "off",
                required:       true,
                className:      css(MakeInput(theme.input, {
                    fontSize:       16,
                    width:          "100%",
                }), Styles.input)
            }} />
            <label {...{
                htmlFor:    "password",
                className:  css(FormStyles.label, Styles.label),
            }}>
                Password
                <SmoothErrorText className={ css(FormStyles.label_error) } motion={["fade", "swipe-right"]} >
                    { errors.password?.message }
                </SmoothErrorText>
            </label>
            <div className={css(Styles.pass)}>
                <input {...{
                    ...register("password", {
                        required: true,
                    }),
                    id:             "password",
                    type:           showPass ? "text" : "password",
                    required:       true,
                    autoComplete:   "off",
                    className:      css(MakeInput(theme.input, {
                        fontSize:       16,
                        padding:        "8px 40px 8px 12px",
                        error:          Boolean(errors.password),
                        width:          "100%",
                    }), Styles.input)
                }} />
                <div className={css(Styles.passIcon)} onClick={() => setShowPass(!showPass)}>
                    {showPass ? <EyeOff /> : <Eye />}
                </div>
            </div>
            <LoadButton {...{
                type:       "submit",
                disabled:   isEmpty,
                loading:    Loading,
                className:  css(Styles.submit),
                theme:      theme.button, 
                layout:     {
                    width:          "100%",
                    fontSize:       16,
                    borderRadius:   6,
                    padding:        "10px 12px",
                    disabled:       isEmpty,
                },
            }}>
                Create account
            </LoadButton>
        </form>
    );
}

const StylesWithTheme = CreateScheetWithTheme((theme?: SignUpTheme) => { return {
    form: {
        display:        "flex",
        flexDirection:  "column",
    },
    label: {
        color:          theme?.label_color,
    },
    input: {
        display:        "block",
        marginBottom:   20,
    },
    submit: {
        display:        "block",
        marginTop:      20,
    },
    pass: {
        position: "relative",
    },
    passIcon: {
        position: "absolute",
        top: "50%",
        right: 15,
        width: 20,
        height: 20,
        marginTop: -10,
        cursor: "pointer",
        zIndex: 2,
        ':nth-child(1n) svg': {
            display: "block",
            width: 20,
            height: 20,
            fill: Primary.default
        },
    }
}})