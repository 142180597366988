import * as runtypes from 'runtypes';

export const FileSourceRuntime = runtypes.Record({
    id:     runtypes.Number,
    url:    runtypes.String,
    type:   runtypes.String,
});

export const FileSourcesRuntime = runtypes.Array(FileSourceRuntime);

export const FileRuntime = runtypes.Record({
    id:         runtypes.Number,
    type:       runtypes.String.Or(runtypes.Null),
    title:      runtypes.String,
    created_at: runtypes.Number,
    sources:    runtypes.Dictionary(FileSourceRuntime , runtypes.String).Or(runtypes.Null),
    thumbnails: runtypes.Dictionary(FileSourceRuntime , runtypes.String).Or(runtypes.Null),
});

export type FileSource = runtypes.Static<typeof FileSourceRuntime>
export type File = runtypes.Static<typeof FileRuntime>

export function GetFileImage(file: File):FileSource | undefined {  
   return (file.sources && file.sources.jpeg) ? file.sources.jpeg : (file.sources && file.sources.png) ? file.sources.png : undefined;
}

export function GetFileImageLink(file: File):string | undefined {  
    const image = GetFileImage(file)
    if(image && image.url) {
        return image.url
    }
    return "";
 }

export function GetFileThumbnail(file: File):FileSource | undefined {
    return (file.thumbnails && file.thumbnails.jpeg) ? file.thumbnails.jpeg : (file.thumbnails && file.thumbnails.png) ? file.thumbnails.png : undefined;
}

export function GetFileThumbnailWebp(file: File):FileSource | undefined {
    return (file.thumbnails && file.thumbnails.webp) ? file.thumbnails.webp : undefined;
}