import * as r from "runtypes";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { put } from "packages/rest/api";

export type ChangeUserData = {
    full_name: string,
    company_name: string,
    company_url: string,
    company_logo: number | null,
}

export default async function SaveUserData(body:ChangeUserData) {
    const results = await put<true>({ 
        url: "users", 
        body 
    });

    if (results[1] !== null && results[1].code === 4) {
        window.location.href = "/"
    }

    return CheckResultRuntime(r.Literal(true), results);
}