import * as r from "runtypes";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { put } from "packages/rest/api";

type ChangePasswordData = {
    old_password: string,
    new_password: string,
}

export default async function ChangePassword(body:ChangePasswordData) {
    const results = await put<true>({ 
        url: "users/passwords", 
        body,
    });

    if (results[1] !== null && results[1].code === 4) {
        window.location.href = "/"
    }

    return CheckResultRuntime(r.Literal(true), results);
}