import Input, { InputTheme, InputLayout } from 'packages/forms/Input';
import { Primary, Secondary, Danger, Success, Dark, Gray } from './colors';

export const DefaultLayout:InputLayout = {
    fontFamily:         "'Poppins', sans-serif",
    fontSize:           14,
    borderRadius:       6,
    border:             "1px solid transparent",
    padding:            "8px 12px",
    transition:         "box-shadow 0.2s",
}

export const PrimaryInput:InputTheme = {
    plain: {
        background:         "white",
        color:              Dark.default,
        borderColor:        Primary.default,
        boxShadow:          undefined,
    },
    placeholder: {
        color:              Dark.disabled,
    },
    focus: {
        background:         undefined,
        color:              undefined,
        borderColor:        Primary.default,
        boxShadow:          "0 3px 10px 0 rgba(34,41,47,.1)",
    },
    valid: {
        background:         undefined,
        color:              Dark.default,
        borderColor:        Success.default,
        boxShadow:          "none",
    },
    error: {
        background:         undefined,
        color:              Dark.default,
        borderColor:        Danger.default,
        boxShadow:          "none",
    },
    disabled: {
        background:         undefined,
        color:              Dark.default,
        borderColor:        Dark.disabled,
        boxShadow:          "none",
    }
}

export const TitleInput: InputTheme = {
    plain: {
        background: "transparent",
        color: Dark.default,
        borderColor: "transparent",
        boxShadow: undefined,
    },
    placeholder: {
        color: Dark.disabled,
    },
    focus: {
        background: "white",
        color: Dark.default,
        borderColor: Primary.default,
        boxShadow: "0 3px 10px 0 rgba(34,41,47,.1)",
    },
    valid: {
        background: undefined,
        color: Dark.default,
        borderColor: Success.default,
        boxShadow: "none",
    },
    error: {
        background: undefined,
        color: Dark.default,
        borderColor: Danger.default,
        boxShadow: "none",
    },
    disabled: {
        background: undefined,
        color: Dark.default,
        borderColor: Dark.disabled,
        boxShadow: "none",
    }
}

export const TransparentGray:InputTheme = {
    plain: {
        background:         "transparent",
        color:              Dark.default,
        borderColor:        Gray.default,
        boxShadow:          undefined,
    },
    placeholder: {
        color:              Dark.disabled,
    },
    focus: {
        background:         undefined,
        color:              undefined,
        borderColor:        Secondary.default,
        boxShadow:          "0 3px 10px 0 rgba(34,41,47,.1)",
    },
    valid: {
        background:         undefined,
        color:              Dark.default,
        borderColor:        Success.default,
        boxShadow:          "none",
    },
    error: {
        background:         undefined,
        color:              Dark.default,
        borderColor:        Danger.default,
        boxShadow:          "none",
    },
    disabled: {
        background:         undefined,
        color:              Dark.default,
        borderColor:        Dark.disabled,
        boxShadow:          "none",
    }
}

export function MakeInput(theme: InputTheme, layout?: InputLayout, ) {
    return Input({...DefaultLayout, ...layout}, theme);
} 