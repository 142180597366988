import { Error, ErrorFactory } from "./model";

export const ErrNotSupported:Error = ErrorFactory({
    code: 1,
    text: "Browser is not supported"
});

export const ErrCantSign:Error = ErrorFactory({
    code: 2,
    text: "Can not sign request"
});

export const ErrCantGetUploadID:Error = ErrorFactory({
    code: 3,
    text: "Can not get upload ID"
});

export const ErrCantUploadPart:Error = ErrorFactory({
    code: 4,
    text: "Can not upload part"
});

export const ErrCantComplete:Error = ErrorFactory({
    code: 5,
    text: "Can not complete upload"
});

export const ErrMissingUploader:Error = ErrorFactory({
    code: 6,
    text: "Can not find uploader"
});