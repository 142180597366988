import { css, CreateScheetWithTheme, useTheme } from "packages/aphrodite/aphrodite";
import { history } from "packages/history/history";
import LogoImage from './img/rapid_mpy.png';
import { LogoTheme } from "./Logo.theme";

export default function Logo({ theme }: { theme: LogoTheme }) {
    const Styles = useTheme(theme, StylesTheme)

    const OpenPage = (url: string) => (e?: React.MouseEvent) => {
        e && e.preventDefault();
        e && e.stopPropagation();

        history.push(`/${url}`);
    }

    return (
        <div className={ css(Styles.header) }>
            <div className={ css(Styles.nav)}> 
    {/* <img className={ css(Styles.image) } src="https://ichef.bbci.co.uk/news/976/cpsprodpb/12A9B/production/_111434467_gettyimages-1143489763.jpg"/>   */}   
                <a {...{
                    className:  css(Styles.logo),
                    href:       "/",
                    onClick:    OpenPage(""),
                }}>
                    <img src={LogoImage} height="80" alt="" />
                </a>
            </div>
        </div>
    )
}


const StylesTheme = CreateScheetWithTheme((theme?: LogoTheme) => { return {
    header: {
        display:    "flex",
        alignItems: "center",
        height:     "100%",
    },
    image: {
        height: 50,
        width: 50,
        marginRight: 20,
    },
    logo: {
        cursor:         "pointer",
        userSelect:     "none",
        textDecoration: "none",
    },
    nav: {
        flexGrow:       1,
        display:        'flex',
        justifyContent: 'center',
    }
}});